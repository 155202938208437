import React from "react";
import social from "../../assets/images/icons/social.svg";
import email from "../../assets/images/icons/email.svg";
import phone from "../../assets/images/icons/phone.svg";
import map from "../../assets/images/icons/map.svg";
import twt from "../../assets/images/icons/twitter.svg";
import fb from "../../assets/images/icons/facebook.svg";
import wp from "../../assets/images/icons/whatsapp.svg";
import ig from "../../assets/images/icons/instagram.svg";

const Contacts = () => {
  return (
    <section
      className="section gray-bg triangles-bg section-spacing-40"
      id="contacts"
    >
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="contacts-container flex">
              <div className="socials contacts-item">
                <div className="contacts-head flex">
                  <img src={social} className="contacts-icon" />
                  <h4>Social Media</h4>
                </div>
                <div className="contacts-body">
                  <div className="social-media flex">
                    <a
                      target="_blank"
                      href="https://wa.me/256709715052"
                      className="social-icon"
                    >
                      <img src={wp} />
                    </a>
                    <a
                      target="_blank"
                      href="https://facebook.com/tthotelug"
                      className="social-icon"
                    >
                      <img src={fb} />
                    </a>
                    <a
                      href="https://twitter.com/tthotelug"
                      className="social-icon"
                    >
                      <img src={twt} />
                    </a>
                    <a
                      target="_blank"
                      href="https://instagram.com/twintowerhotel"
                      className="social-icon"
                    >
                      <img src={ig} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="tel contacts-item">
                <div className="contacts-head flex">
                  <img src={phone} className="contacts-icon" />
                  <h4>Telephone</h4>
                </div>
                <div className="contacts-body">
                  <p>+256 709 715 052</p>
                </div>
              </div>
              <div className="email contacts-item">
                <div className="contacts-head flex">
                  <img src={email} className="contacts-icon" />
                  <h4>Email</h4>
                </div>
                <div className="contacts-body">
                  <p>twintowershotelltd@gmail.com</p>
                  {/*<p>booking@twintowerhotel.com</p>*/}
                </div>
              </div>
              <div className="address contacts-item">
                <div className="contacts-head flex">
                  <img src={map} className="contacts-icon" />
                  <h4>Address</h4>
                </div>
                <div className="contacts-body">
                  <p>Makerere Kavule, Kiggundu Zone</p>
                  <p>Plot 934 Bombo Road, Kampala Uganda</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
